<script setup>
import NowPlayingToolbar from '@/components/NowPlayingToolbar.vue';
import { useTrackStore } from '@/stores/tracks';
import { ref, watch } from 'vue';

const trackStore = useTrackStore();

const isPlaying = ref(trackStore.currentlyPlayingData?.is_playing);

watch(
    () => trackStore.currentlyPlayingTrackData,
    () => {
        isPlaying.value = trackStore.currentlyPlayingTrackData.is_playing;
    },
    { deep: true }
);
</script>

<template>
    <NowPlayingToolbar class="layout-footer" v-if="isPlaying" />
    <span class="layout-footer" v-else
        >Content provided by
        <a href="https://spotify.com" target="_blank" rel="noopener noreferrer" class="text-primary font-bold hover:underline"><img src="/images/Spotify_Logo_CMYK_Green.png" alt="Spotify" class="max-w-24 inline-block align-middle" /> </a>
    </span>
</template>
