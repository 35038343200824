import { setRequireSessionRefresh } from '@/stores/user';
import { useEnv } from '@/util/env';
import { useConfirm } from 'primevue/useconfirm';

const env = useEnv();

export function useAppConfirm() {
    const confirm = useConfirm();

    function showUpgradeDialog(showUpgradeDialogRef) {
        showUpgradeDialogRef.value = true;
        confirm.require({
            message: "You've reached the maximum number of free tags 😕. Upgrade to Premium to add more tags.",
            header: 'Whoops!',
            icon: 'pi pi-exclamation-triangle',
            rejectProps: {
                label: 'Cancel',
                severity: 'secondary',
                outlined: true
            },
            acceptProps: {
                label: 'Upgrade',
                severity: 'success'
            },
            accept: () => {
                setRequireSessionRefresh();
                const session = JSON.parse(sessionStorage.getItem('session'));
                let url = env.isProduction ? 'https://buy.stripe.com/dR602P4OQ2Kb9ZSaEE' : 'https://buy.stripe.com/test_4gwg1p1iZ5Hl2NWcMN';
                url += '?client_reference_id=' + session.user.id;
                window.open(url);
            },
            reject: () => {
                showUpgradeDialogRef.value = false;
            }
        });
    }

    return { showUpgradeDialog };
}
