import { SupabaseService } from '@/service/SupabaseService';
import { useTrackTagStore } from '@/stores/trackTags';
import { useTagSorter } from '@/util/tagSorter';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

const tagSorter = useTagSorter();

export const useTagStore = defineStore('tags', () => {
    const BULK_TAG_TYPES = {
        MANUAL: 'manual',
        AUTO: 'auto'
    };

    const BULK_TAG_ACTIONS = {
        ADD: 'add',
        REMOVE: 'remove'
    };

    const AUTO_TAG_TYPES = {
        GENRE: 'genre',
        DECADE: 'decade',
        POPULARITY: 'popularity',
        EXPLICITNESS: 'explicitness'
    };

    const trackTagStore = useTrackTagStore();

    const tags = ref([]);

    const orderedTags = computed(() => (tags) => tagSorter.sortTags(tags));

    async function init() {
        await SupabaseService.getTags().then((tagsData) => (tags.value = tagsData));
    }

    async function upsertTag(newTag) {
        const isNew = !newTag.id;
        const data = await SupabaseService.upsertTag(newTag.id, newTag.name);
        if (data) {
            newTag = data[0];
            if (isNew) {
                tags.value.push(newTag);
            } else {
                const index = tags.value.findIndex((tag) => tag.id === newTag.id);
                tags.value[index] = newTag;
            }
            return data;
        }
    }

    async function insertTags(newTagNames) {
        if (!Array.isArray(newTagNames)) newTagNames = [newTagNames];
        const data = await SupabaseService.insertTags(newTagNames);
        if (data) {
            data.forEach((newTag) => {
                tags.value.push(newTag);
            });
            return data;
        }
    }

    async function deleteTags(tagIds) {
        if (!Array.isArray(tagIds)) tagIds = [tagIds];
        const data = await SupabaseService.deleteTags(tagIds);
        tags.value = tags.value.filter((tag) => !tagIds.includes(tag.id));
        trackTagStore.deleteTagsFromTracks(tagIds);
        return data;
    }

    return { BULK_TAG_TYPES, BULK_TAG_ACTIONS, AUTO_TAG_TYPES, tags, orderedTags, init, upsertTag, insertTags, deleteTags };
});
