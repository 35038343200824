import AppLayout from '@/layout/AppLayout.vue';
import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/Home.vue')
        },
        {
            path: '/error',
            name: 'error',
            component: () => import('@/views/Error.vue')
        },
        {
            path: '/dashboard',
            component: AppLayout,
            children: [
                {
                    path: '/dashboard',
                    name: 'dashboard',
                    meta: { requiresAuth: true },
                    component: () => import('@/views/Dashboard.vue')
                },
                {
                    path: '/dashboard/account',
                    name: 'account',
                    meta: { requiresAuth: true },
                    component: () => import('@/views/Account.vue')
                },
                {
                    path: '/dashboard/help',
                    name: 'help',
                    meta: { requiresAuth: true },
                    component: () => import('@/views/Help.vue')
                },
                {
                    path: '/dashboard/tags',
                    name: 'tags',
                    meta: { requiresAuth: true },
                    component: () => import('@/views/Tags.vue')
                },
                {
                    path: '/dashboard/tags/:id',
                    name: 'tag',
                    props: true,
                    meta: { requiresAuth: true },
                    component: () => import('@/views/Tag.vue')
                },
                {
                    path: '/dashboard/playlists/:id',
                    name: 'playlist',
                    props: true,
                    meta: { requiresAuth: true },
                    component: () => import('@/views/Playlist.vue')
                },
                {
                    path: '/dashboard/playlists/saved-tracks',
                    name: 'saved-tracks',
                    meta: { requiresAuth: true },
                    component: () => import('@/views/SavedTracks.vue')
                }
            ]
        }
    ]
});

router.beforeEach((to) => {
    const isAuthenticated = Boolean(sessionStorage.session) && Boolean(JSON.parse(sessionStorage.session));
    if (!isAuthenticated && to.meta.requiresAuth) {
        return { path: '/' };
    }
});

export default router;
