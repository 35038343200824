import { SpotifyService } from '@/service/SpotifyService';
import { useTagStore } from '@/stores/tags';
import { useTrackTagStore } from '@/stores/trackTags';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useTrackStore = defineStore('tracks', () => {
    const tagStore = useTagStore();
    const trackTagStore = useTrackTagStore();

    const tracks = ref([]);
    const currentlyPlayingTrackData = ref({});
    const isLoading = ref(false);

    async function init() {
        await this.getCurrentlyPlayingTrackData();
    }

    async function getCurrentlyPlayingTrackData() {
        isLoading.value = true;
        const trackData = await SpotifyService.getCurrentlyPlayingTrackData();
        currentlyPlayingTrackData.value = trackData;
        isLoading.value = false;
        return currentlyPlayingTrackData.value;
    }

    async function getUsersSavedTracksData(next) {
        isLoading.value = true;
        const tracksData = await SpotifyService.getUsersSavedTracks(next);
        tracksData.items
            .map((item) => item.track)
            .map((track) => {
                const tagIds = trackTagStore.trackTags.find((trackTag) => trackTag.spotify_id === track.id)?.tag_ids;
                track.tags = tagStore.tags.filter((tag) => tagIds?.includes(tag.id));
                track.artistNames = track.artists.map((artist) => artist.name).join(', ');
            });
        isLoading.value = false;
        return tracksData;
    }

    async function getPlaylistTracksData(playlist, next) {
        isLoading.value = true;
        const playlistTracksData = await SpotifyService.getPlaylistTracks(playlist, next);
        playlistTracksData.items
            .map((item) => item.track)
            .map((track) => {
                const tagIds = trackTagStore.trackTags.find((trackTag) => trackTag.spotify_id === track.id)?.tag_ids;
                track.tags = tagStore.tags.filter((tag) => tagIds?.includes(tag.id));
                track.artistNames = track.artists.map((artist) => artist.name).join(', ');
            });
        isLoading.value = false;
        return playlistTracksData;
    }

    async function getTracks(trackIds) {
        if (!Array.isArray(trackIds)) {
            trackIds = [trackIds];
        }
        if (trackIds.length === 0) {
            return [];
        }
        isLoading.value = true;
        const tracksData = await SpotifyService.getTracks(trackIds);
        tracksData.map((track) => {
            const tagIds = trackTagStore.trackTags.find((trackTag) => trackTag.spotify_id === track.id)?.tag_ids;
            track.tags = tagStore.tags.filter((tag) => tagIds?.includes(tag.id));
            track.artistNames = track.artists.map((artist) => artist.name).join(', ');
        });
        isLoading.value = false;
        return tracksData;
    }

    return { tracks, currentlyPlayingTrackData, isLoading, init, getCurrentlyPlayingTrackData, getUsersSavedTracksData, getPlaylistTracksData, getTracks };
});
