import { SupabaseService } from '@/service/SupabaseService';
import { defineStore } from 'pinia';

const requireSessionRefreshKey = 'requireSessionRefresh';

export function setRequireSessionRefresh() {
    sessionStorage.setItem(requireSessionRefreshKey, true);
}

export const useUserStore = defineStore('user', () => {
    const FREE_TIER_MAX_TAGS = 3;

    const SUBSCRIPTION_TIERS = {
        FREE: 'Free',
        PREMIUM: 'Premium'
    };

    const subscription = hasPremium() ? SUBSCRIPTION_TIERS.PREMIUM : SUBSCRIPTION_TIERS.FREE;

    function hasPremium() {
        const requireSessionRefresh = sessionStorage.getItem(requireSessionRefreshKey) === 'true';
        if (requireSessionRefresh === true) {
            // This is a little hacky... the client has no way of knowing when the webhook processor has updated
            // the DB with the new subscription status. So, when the user clicks the Stripe Buy Button, we set a
            // flag for ourselves on the sessionStorage to force ourselves to refresh the session the next time we
            // need to check the subscription status. Hopefully, the webhook processor will have updated the DB by then.
            // Alternatively, we could implement Stripe Checkout instead of Payment Links, but that's a lot more work.
            Promise.resolve(SupabaseService.refreshSession());
            sessionStorage.setItem(requireSessionRefreshKey, false);
        }
        // NOTE: The "premium" magic string is defined by the Stripe Entitlement's Lookup Key.
        return JSON.parse(sessionStorage.session).user.user_metadata.stripe_entitlement === 'premium';
    }

    return { FREE_TIER_MAX_TAGS, SUBSCRIPTION_TIERS, subscription, hasPremium };
});
