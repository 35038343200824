<script setup>
import { useTagStore } from '@/stores/tags';
import { useTrackTagStore } from '@/stores/trackTags';
import { useUserStore } from '@/stores/user';
import { useAppConfirm } from '@/util/confirm';
import { useTagSorter } from '@/util/tagSorter';
import { useAppToast } from '@/util/toast';
import { computed, ref } from 'vue';

const confirm = useAppConfirm();
const toast = useAppToast();
const tagSorter = useTagSorter();
const userStore = useUserStore();
const tagStore = useTagStore();
const trackTagStore = useTrackTagStore();

defineProps({
    track: Object,
    menuRefFunc: Function
});

const menuSearchQuery = ref('');
const newTagName = ref('');
const showUpgradeDialog = ref(false);

const filteredTags = computed(() => {
    const tags = tagStore.tags.filter((tag) => tag.name?.toLowerCase().includes(menuSearchQuery?.value.toLowerCase()));
    return tagSorter.sortTags(tags);
});

async function tagTrack(track, tag, checked) {
    try {
        if (!userStore.hasPremium() && track.tags?.length >= userStore.FREE_TIER_MAX_TAGS && checked) {
            confirm.showUpgradeDialog(showUpgradeDialog);
            return;
        }
        await trackTagStore.tagTrack(track, tag, checked);
        checked ? toast.success('Track tagged: ' + tag.name) : toast.success('Track untagged: ' + tag.name);
    } catch (error) {
        toast.error(error.message);
    }
}

async function addTagAndTagTrack(tagName, track) {
    try {
        const data = await tagStore.upsertTag({ name: tagName });
        const tag = data[0];
        newTagName.value = '';
        toast.success('Tag added: ' + tag.name);
        tagTrack(track, tag, true);
    } catch (error) {
        if (error.code === '23505') {
            toast.error('Tag already exists: ' + tagName);
        } else {
            toast.error(error.message);
        }
    }
}
</script>

<template>
    <ConfirmDialog v-model:visible="showUpgradeDialog"></ConfirmDialog>
    <Menu :ref="menuRefFunc" :model="filteredTags" :popup="true" pt:list:style="overflow-y: scroll; max-height: 262px">
        <template #start>
            <InputGroup v-show="tagStore.tags.length > 0">
                <InputText placeholder="Search tags" v-model="menuSearchQuery" />
                <Button icon="pi pi-search" severity="secondary" />
            </InputGroup>
        </template>
        <template #item="{ item, props }">
            <a
                v-ripple
                class="flex items-center"
                v-bind="props.action"
                @click="
                    $event.target.querySelector('input[type=checkbox]')?.click();
                    $event.stopPropagation(); // Prevents the menu from closing
                "
            >
                <input type="checkbox" :name="track.id + ':' + item.id" :checked="track.tags.map((tag) => tag.id).includes(item.id) ? 'true' : null" @change="tagTrack(track, item, $event.target.checked)" />
                <label
                    :for="track.id + ':' + item.id"
                    @click="
                        $event.target.previousElementSibling?.click();
                        $event.stopPropagation(); // Prevents the menu from closing
                    "
                    >{{ item.name }}</label
                >
            </a>
        </template>
        <template #end>
            <router-link to="/dashboard/tags" v-show="tagStore.tags.length > 0">
                <Button label="Manage tags" severity="secondary" link />
            </router-link>
            <InputGroup>
                <InputText placeholder="New tag" v-model="newTagName" />
                <Button icon="pi pi-plus-circle" severity="success" @click="addTagAndTagTrack(newTagName, track)" class="full-width-button" />
            </InputGroup>
        </template>
    </Menu>
</template>
