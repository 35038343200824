import { SupabaseService } from '@/service/SupabaseService';
import { useTagStore } from '@/stores/tags';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useTrackTagStore = defineStore('trackTags', () => {
    const tagStore = useTagStore();

    const trackTags = ref([]);

    async function init() {
        const trackTagsData = await SupabaseService.getTrackTagsBySpotifyId();
        trackTagsData.map((trackTag) => {
            trackTag.tags = tagStore.tags.filter((tag) => trackTag.tag_ids?.includes(tag.id));
        });
        trackTags.value = trackTagsData;
    }

    async function tagTrack(track, tag, checked) {
        await SupabaseService.tagTrack(track.id, tag.id, checked);
        track.tags = checked ? [...new Set([...track.tags, tag])] : track.tags.filter((t) => t.id !== tag.id);
    }

    async function tagTracks(tracks, tags, checked) {
        if (!Array.isArray(tracks)) tracks = [tracks];
        if (!Array.isArray(tags)) tags = [tags];
        await SupabaseService.tagTracks(
            tracks.map((track) => track.id),
            tags.map((tag) => tag.id),
            checked
        );
        tracks.forEach((track) => {
            track.tags = checked ? [...new Set([...track.tags, ...tags])] : track.tags.filter((t) => !tags.includes(t));
        });
    }

    async function upsertTrackTags(trackTags, tracks) {
        await SupabaseService.upsertTrackTags(trackTags);
        trackTags.forEach((trackTag) => {
            const foundTags = tagStore.tags.filter((tag) => trackTag.tag_id === tag.id);
            trackTag.tags = trackTag.tags ? [...trackTag.tags, ...foundTags] : [...foundTags];
            const track = tracks.find((track) => track.id === trackTag.spotify_id);
            track.tags = [...new Set([...track.tags, ...trackTag.tags])];
        });
    }

    async function deleteTagsFromTracks(tagIds) {
        if (!Array.isArray(tagIds)) tagIds = [tagIds];
        trackTags.value.forEach((trackTag) => {
            trackTag.tags = trackTag.tags.filter((tag) => !tagIds.includes(tag.id));
            trackTag.tag_ids = trackTag.tag_ids.filter((tagId) => !tagIds.includes(tagId));
        });
    }

    return { trackTags, init, tagTrack, tagTracks, upsertTrackTags, deleteTagsFromTracks };
});
