<script setup>
import { usePlaylistStore } from '@/stores/playlists';
import { useTagStore } from '@/stores/tags';
import { PrimeIcons } from '@primevue/core/api';
import { vInfiniteScroll } from '@vueuse/components';
import { onBeforeMount, ref, watch } from 'vue';
import AppMenuItem from './AppMenuItem.vue';

const tagStore = useTagStore();
const playlistStore = usePlaylistStore();

onBeforeMount(() => {
    updateMenuTags();
    updateMenuPlaylists();
});

watch(() => tagStore.tags, updateMenuTags, { deep: true });
watch(() => playlistStore.playlists, updateMenuPlaylists, { deep: true });

const menuItems = ref([
    {
        label: 'Home',
        items: [
            {
                label: 'Dashboard',
                icon: PrimeIcons.HOME,
                to: '/dashboard'
            }
        ]
    },
    {
        label: 'Your Tags',
        cornerIcon: {
            icon: PrimeIcons.COG,
            to: '/dashboard/tags'
        },
        items: []
    },
    {
        label: 'Your Playlists',
        items: []
    }
]);

function updateMenuTags() {
    menuItems.value[1].items = tagStore.tags.map((tag) => ({
        label: tag.name,
        icon: PrimeIcons.TAG,
        to: `/dashboard/tags/${tag.id}`
    }));
}

function updateMenuPlaylists() {
    menuItems.value[2].items = playlistStore.playlists.map((playlist) => ({
        label: playlist.name,
        icon: PrimeIcons.LIST,
        to: `/dashboard/playlists/${playlist.id}`
    }));
    menuItems.value[2].items.unshift({
        label: 'Saved Tracks',
        icon: PrimeIcons.CHECK_CIRCLE,
        to: '/dashboard/playlists/saved-tracks'
    });
}

async function onLoadMore() {
    await playlistStore.getNextPlaylists();
}
</script>

<template>
    <div v-infinite-scroll="[onLoadMore, { interval: 500, canLoadMore: () => playlistStore.playlistsNextHref !== null }]">
        <ul class="layout-menu">
            <template v-for="(item, i) in menuItems" :key="item">
                <app-menu-item v-if="!item.separator" :item="item" :index="i" ref="el"></app-menu-item>
                <li v-if="item.separator" class="menu-separator"></li>
            </template>
        </ul>
    </div>
</template>

<style lang="scss" scoped></style>
