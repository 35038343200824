import { useToast } from 'primevue/usetoast';

export function useAppToast() {
    const defaultLifeTimeMilliseconds = 3000;
    const toast = useToast();

    function success(message, isSticky = false) {
        toast.add({ severity: 'success', summary: '', detail: message, group: 'bl', life: isSticky ? null : defaultLifeTimeMilliseconds });
    }

    function info(message, isSticky = false) {
        toast.add({ severity: 'info', summary: '', detail: message, group: 'bl', life: isSticky ? null : defaultLifeTimeMilliseconds });
    }

    function warn(message, isSticky = false) {
        toast.add({ severity: 'warn', summary: '', detail: message, group: 'bl', life: isSticky ? null : defaultLifeTimeMilliseconds });
    }

    function error(message, isSticky = false) {
        toast.add({ severity: 'error', summary: '', detail: message, group: 'bl', life: isSticky ? null : defaultLifeTimeMilliseconds });
    }

    return { success, info, warn, error };
}
